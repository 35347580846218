@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lexend:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  --roboto: "Roboto", sans-serif;
  --inter: "Inter", sans-serif;
  --lexend: "Lexend", sans-serif;
  --poppins: "Poppins", sans-serif;
  --blue-color: #226ddb;
}

body {
  margin: 0;
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
ul,
ol {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  outline: none;
  border: none;
}
input {
  outline: none;
  border: none;
}
.overview_text {
  font-size: 20px;
  font-family: var(--lexend);
}
.statsbox {
  background-color: #edf6ff;
}
.stats_icon_box {
  width: 44px;
  height: 44px;
}
.stats_title_text {
  font-size: 16px;
  font-size: var(--lexend);
}
.uppercase{
  text-transform: uppercase;
}
.downloadbtn {
  width: 45px;
  height: 45px;
  cursor: pointer;
  background: linear-gradient(
    120deg,
    rgba(147, 190, 255, 1) 0%,
    rgba(0, 82, 204, 1) 93%
  );
}
.statsbox h3 {
  font-size: clamp(32px, 6vw, 20px);
  font-family: var(--lexend);
}
.stat_percent_text {
  font-size: 14px;
  color: #117b34;
  font-weight: bold;
  font-family: var(--inter);
}
.stat_percent_text span {
  font-weight: 500;
  color: #565d6d;
}
.table {
  width: 100%;
}

.table td {
  vertical-align: middle;
  font-size: clamp(12px, 1vw, 14px);
  border-top: 1px solid #e5e5e5;
  padding: 20px 10px;
  font-family: var(--inter);
}
.table th {
  color: var(--seclight);
  font-size: 18px;
  box-sizing: border-box;
  padding: 0px 10px;
  padding-bottom: 20px;
  font-family: var(--roboto);
}
.createbtn {
  font-family: var(--roboto);
  font-weight: 600;
  background-color: var(--blue-color);
}
.pages_button_wrap {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin-left: 15px;
}
.page_btn {
  height: 30px;
  width: 30px;
  border-radius: 100px;
  font-size: 12px;
  font-family: var(--roboto);
  color: #9095a1;
  margin: 10px 0;
}
.slider_wrap {
  width: 100%;
  height: 100%;
  margin-top: 30px;
}
.slides {
  width: 100%;
  height: 100%;
  background-color: var(--blue-color);
  border-radius: 15px;
}
.pages_button_wrap p {
  font-size: 12px;
  font-weight: 600;
}
.modal_label_text {
  font-size: 16px;
  font-family: var(--roboto);
  font-weight: 400;
}
.modal_input {
  font-size: 14px;
  color: #18181b;
  font-family: var(--roboto);
  font-weight: 400;
}
.createModal_btn {
  font-size: 18px;
  font-family: var(--roboto);
  font-weight: 400;
}
.modal_heading {
  font-size: 20px;
  font-weight: 400;
  font-family: var(--roboto);
}
.rememberMe_wrap {
  font-size: 14px;
  font-family: var(--inter);
  font-weight: 700;
}
.login_btn {
  font-size: 20px;
  font-weight: bold;
  font-family: var(--inter);
  background-color: var(--blue-color);
}
.login_bottom_text {
  font-size: 14px;
  font-family: var(--inter);
}
.login_logo {
  font-size: clamp(35px, 10vw, 25px);
  font-family: var(--lexend);
  color: var(--blue-color);
}
.login_text {
  font-size: 16px;
  font-family: var(--poppins);
  color: #313131;
}
.welcome_text {
  font-size: clamp(40px, 12vw, 30px);
  font-family: var(--poppins);
}
.space {
  height: 50px;
}
.clientinfo_input_wrap {
  width: 100%;
  height: 40px;
  border-color: #e5e5e5;
  font-size: 14px;
  font-family: var(--inter);
}
#client_input {
  border-color: var(--blue-color);
}
#client_input {
  height: 40px;
}
.clientinfo_input_wrap input::placeholder {
  color: #a1a1aa;
}
.clientInfo_btn {
  width: 180px;
  height: 48px;
  border-color: #d4d4d8;
  background-color: hsl(0, 0%, 100%);
  color: #27272a;
  transition: 250ms ease-in-out;
  font-size: 18px;
  font-weight: 400;
  font-family: var(--roboto);
}
.clientInfo_btn:hover {
  background-color: var(--blue-color);
  color: #fff !important;
  border-color: var(--blue-color);
}

.login_dropdown{
  font-family: var(--inter);
  font-weight: 400;
  height: 50px;
  font-size: 16px;
  border: 1px solid lightgrey;
  color: #9A9A9A;
  padding: 0 10px;
  outline: none;
}


@media only screen and (max-width: 800px) {

  .table tbody,
  .table tr,
  .table td {
    display: block;
  }
  .table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .table td {
    position: relative;
    padding-left: 50%;
    border: none;
    border-bottom: 1px solid #eee;
  }
  .table td:before {
    content: attr(data-title) ": ";
    position: absolute;
    left: 6px;
    font-weight: bold;
    font-size: 14px !important;
    color: var(--seclight);
  }
  .table tr {
    border-bottom: 1px solid #ccc;
  }
  .clientInfo_btn {
    width: 150px;
    height: 44px;
    font-size: 16px;
  }
}
