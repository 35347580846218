body{
    font-family: sans-serif;
  }
  
  .label-float{
    position: relative;
    padding-top: 9px;
  }
  
  .label-float input{
    border: 1px solid lightgrey;
    border-radius: 10px;
    outline: none;
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-family: var(--inter);
    transition: all .1s linear;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    -webkit-appearance:none;
  }
  
  .label-float input:focus{
    border: 2px solid #226DDB;
  }
  
  .label-float input::placeholder{
    color:transparent;
  }
  
  .label-float label{
    pointer-events: none;
    position: absolute;
    top: calc(50% - 8px);
    left: 15px;
    font-family: var(--inter);
    transition: all .1s linear;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    background-color: white;
   
    color: #9A9A9A;
    box-sizing: border-box;
  }
  
  .label-float input:required:invalid + label{
    color: red;
  }
  .label-float input:focus:required:invalid{
    border: 2px solid red;
  }
  .label-float input:required:invalid + label:before{
    content: '*';
  }
  .label-float input:focus + label,
  .label-float input:not(:placeholder-shown) + label{
    font-size: 13px;
    top: 0;
    color: #226DDB;
  }
  