.navbar_searchbar {
  width: 321px;
  height: 35px;
  background-color: #f8f8f8;
}
.navbar_searchbar input {
  font-size: 14px;
  background-color: #f8f8f8;

}
.profileWrap{
    width: 35px;
    height: 35px;
    background-color: #C2DBFF;
}
.siteLogo{
    font-size: 24px;
    font-weight: bold;
    font-family: var(--laxend);
    color: var(--blue-color)
}

