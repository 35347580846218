
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-pagination-bullet {
    background-color: white; /* Change to your desired pagination color */
  }
  
  /* Adjust width of active pagination circle */
  .swiper-pagination-bullet-active {
    border-radius: 10px;
    width: 20px; /* Change to your desired width for the active pagination circle */
  }
  